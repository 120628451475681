<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">Consider the following reaction:</p>

      <p class="mb-5 pl-8">
        <chemical-latex :content="reaction" />
      </p>

      <p class="mb-3">
        Determine the order with respect to each of the reactants as well as the overall reaction
        order.
      </p>

      <p class="mb-2">a) Order with respect to A</p>
      <calculation-input
        v-model="inputs.orderA"
        prepend-text="$\text{Order:}$"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-2">b) Order with respect to B</p>
      <calculation-input
        v-model="inputs.orderB"
        prepend-text="$\text{Order:}$"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-2">c) Overall reaction order</p>
      <calculation-input
        v-model="inputs.orderTot"
        prepend-text="$\text{Order:}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ResponseFeedback from '../response-utils/ResponseFeedback';
import TaskButtons from '../TaskButtons';
import SubmitButton from '../response-utils/SubmitButton';
import ChemicalSymbolInput from '../inputs/ChemicalSymbolInput';
import ChemicalLatex from '../displayers/ChemicalLatex';
import LatexNumber from '../displayers/LatexNumber';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question86',
  components: {
    StembleLatex,
    ChemicalSymbolInput,
    SubmitButton,
    TaskButtons,
    ResponseFeedback,
    ChemicalLatex,
    LatexNumber,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        orderA: null,
        orderB: null,
        orderTot: null,
      },
    };
  },
  computed: {
    reactionNumber() {
      return this.taskState.getValueBySymbol('arrayVariable').content.value;
    },
    reaction() {
      if (this.reactionNumber === 0) {
        return '\\ce{A + 2B -> products} \\qquad rate=k[A]^2[B]^2';
      } else if (this.reactionNumber === 1) {
        return '\\ce{A + B -> products} \\qquad rate=k[A][B]^2';
      } else if (this.reactionNumber === 2) {
        return '\\ce{2A + 3B -> products} \\qquad rate=k[B]^2';
      } else if (this.reactionNumber === 3) {
        return '\\ce{3A + 2B -> products} \\qquad rate=k[A]^2[B]';
      } else if (this.reactionNumber === 4) {
        return '\\ce{A + B -> products} \\qquad rate=k[B]';
      } else if (this.reactionNumber === 5) {
        return '\\ce{3A + B -> products} \\qquad rate=k[A]^2';
      } else if (this.reactionNumber === 6) {
        return '\\ce{2A + B -> products} \\qquad rate=k[A][B]^2';
      }
    },
  },
};
</script>
